import React, { useState, useEffect, useRef } from 'react'
import { Map, List, fromJS } from 'immutable'
import Radios from './radios'

export default function Templates(props) {
  let {
    value,
    name,
    onChange,
    loading = false,
    templates,
    templateTypeField = 'type',
    templateSourceField = 'source',
    values,
    lang,
    sourceOptions,
    t,
  } = props

  let type = values.get(templateTypeField)
  let source = values.get(templateSourceField)

  let [templateOptions, setTemplateOptions] = useState(List())

  let prevConfig = useRef()

  useEffect(() => {
    let typeSource = type + source + loading.toString()

    if (prevConfig.current !== typeSource) {
      let items = templates.getIn([source, type], List())
      setTemplateOptions(items)
      if (items.size) onChange({ name, value: items.getIn([0, 'id']) })
    }

    prevConfig.current = typeSource
  })

  return (
    <div className="templates">
      <div className="templates-source">
        <Radios name={templateSourceField} value={source} onChange={onChange} options={sourceOptions} />
      </div>
      {source !== 'empty' ? (
        <div className="templates-list">
          {!List.isList(templateOptions) || !templateOptions.size ? (
            <div className="templates-empty">{t('templates.empty')}</div>
          ) : (
            templateOptions.map(template => {
              let id = template.get('id')
              let isSelected = id === value
              let title = template.getIn(['info', lang, 'title'], template.getIn(['info', 'en', 'title']))
              let description = template.getIn(
                ['info', lang, 'description'],
                template.getIn(['info', 'en', 'description'])
              )

              let classes = ['templates-item']
              if (isSelected) classes.push('templates-item--selected')

              return (
                <div className="templates-list-item" key={id}>
                  <button
                    className={classes.join(' ')}
                    onClick={e => e.preventDefault() & onChange({ name, value: id })}>
                    <div className="templates-item-content">
                      <div className="templates-item-content-inner">
                        <div className="templates-item-title">{title}</div>
                        {description ? <div className="templates-item-description">{description}</div> : null}
                      </div>
                    </div>
                    <div className="templates-item-info"></div>
                  </button>
                </div>
              )
            })
          )}
        </div>
      ) : null}
    </div>
  )
}
