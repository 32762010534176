import React, { Component, PureComponent, Fragment } from 'react'
import { Map, List, fromJS } from 'immutable'

export default function Checkboxes({ name, options = [], t, value, onChange }) {
  if (!List.isList(value)) value = List()

  return (
    <div className="form-field form-field--checkboxes">
      {options.length ? (
        options.map(({ value: optionValue, label }) => {
          let classes = ['form-checkbox']
          let isChecked = value.contains(optionValue)

          if (isChecked) classes.push('form-checkbox--checked')

          return (
            <button
              className={classes.join(' ')}
              onClick={e =>
                e.preventDefault() &
                onChange({ name, value: isChecked ? value.filter(v => v !== optionValue) : value.push(optionValue) })
              }
              key={optionValue}>
              <span className="form-checkbox-icon-wrapper">
                <span className="form-checkbox-icon">{isChecked ? <i className="fa fa-check" /> : null}</span>
              </span>
              <span className="form-checkbox-label">{label}</span>
            </button>
          )
        })
      ) : (
        <div className="form-checkbox-empty">{t('common.emptyList')}</div>
      )}
    </div>
  )
}
