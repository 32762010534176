import moment from 'moment'
import React, { useCallback, useState } from 'react'
import Datepicker from 'react-datetime'
import './datetime.sass'

function DatepickerInputContent(props) {
  const { inputProps, date, placeholder } = props
  if (date) {
    const hoursOffset = date.utcOffset() / 60
    return (
      <>
        {inputProps.value} (UTC {hoursOffset < 0 ? hoursOffset : '+' + hoursOffset})
      </>
    )
  } else {
    return <>{placeholder}</>
  }
}

function DatepickerInput(props) {
  const { inputProps, openCalendar, closeCalendar, date, placeholder } = props
  const [open, setOpen] = useState(false)
  const onInputClick = useCallback(
    e => {
      e.preventDefault()
      open ? closeCalendar() : openCalendar()
      setOpen(!open)
    },
    [open]
  )

  return (
    <button className="button button--small button--grey" onClick={onInputClick}>
      <i className="fa fa-calendar" />
      <DatepickerInputContent inputProps={inputProps} date={date} placeholder={placeholder} />
    </button>
  )
}

export default function DatetimeField(props) {
  let { onChange, value, name, minDate, placeholder } = props

  let date
  if (value) date = moment(value)
  else if (!placeholder) date = moment()

  return (
    <div className="form-field form-field--datetime">
      <Datepicker
        className="datetime-datepicker"
        timeFormat={true}
        input={true}
        renderInput={(inputProps, openCalendar, closeCalendar) => (
          <DatepickerInput
            inputProps={inputProps}
            openCalendar={openCalendar}
            closeCalendar={closeCalendar}
            date={date}
            placeholder={placeholder}
          />
        )}
        value={date}
        isValidDate={(currentDate, selectedDate) => {
          if (!minDate) return true
          return !currentDate.isBefore(moment(minDate).startOf('day'))
        }}
        onChange={e => {
          if (typeof e === 'string') {
          } else {
            onChange({
              name,
              value: e.format(),
            })
          }
        }}
      />
    </div>
  )
}
