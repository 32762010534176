// Force build counter: 5
import 'promise-polyfill/src/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from './router'
import Routes from './routes'

import './sass/app.sass'
import './error'

import './sentry'

const render = () =>
  ReactDOM.render(
    <React.StrictMode>
      <Router>{Routes}</Router>
    </React.StrictMode>,
    document.getElementById('app')
  )

if (
  'Map' in window &&
  'Promise' in window &&
  'forEach' in NodeList.prototype &&
  'startsWith' in String.prototype &&
  'endsWith' in String.prototype &&
  'includes' in String.prototype &&
  'includes' in Array.prototype &&
  'assign' in Object &&
  'entries' in Object &&
  'keys' in Object
) {
  render()
} else {
  import('./polyfills').then(render)
}
