import React, { Suspense } from 'react'
const CodeMirror = React.lazy(() => import('./codeMirror'))

export default function CodeEditorField(props) {
  let { mode = 'css', value, name, onChange } = props

  let options = {
    mode,
    lineNumbers: true,
    lineWrapping: true,
    tabSize: 2,
  }

  return (
    <div className="field-input-code-editor">
      <Suspense
        fallback={
          <div className="loader">
            <i className="fa fa-spin fa-spinner" />
          </div>
        }>
        <CodeMirror
          value={value}
          options={options}
          onBeforeChange={(editor, data, value) => onChange({ name, value })}
        />
      </Suspense>
    </div>
  )
}
