import React from 'react'
import { units, parseTime } from 'common/time'

export default function TimeField(props) {
  let { onChange, value, name, timeType = 'time', t } = props

  let { count, unit } = parseTime(value)

  return (
    <div className="form-field form-field--time form-field--select">
      <input
        type="number"
        name="count"
        min="0"
        value={count}
        onChange={e =>
          onChange({
            name,
            value: `${e.target.value}${unit}`,
          })
        }
      />
      <select
        value={unit}
        name="unit"
        onChange={e =>
          onChange({
            name,
            value: `${count}${e.target.value}`,
          })
        }>
        {units.map(unit => {
          return (
            <option value={unit} key={unit}>
              {t(`embedPopup.rules.${timeType}.${unit}`)}
            </option>
          )
        })}
      </select>
    </div>
  )
}
