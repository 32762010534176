import React from 'react'
// @ts-ignore
import Logo from 'dashboard/logo'
// @ts-ignore
import { useResponsive } from 'common/responsiveStore'
import 'dashboard/header.sass'
import 'dashboard/nav.sass'
import 'page/page.sass'
import './loader.sass'
//import { Translator } from '../i18n'

export default function AuthLoader(
  { error = false, t } /*: {
  error: boolean,
  t: Translator
}*/
) {
  let { breakpointWidth } = useResponsive()

  return (
    <div className="page page--auth-loader">
      <div className="header">
        <div className="header-container">
          <div className="header-logo">
            <span>
              <Logo white />
            </span>
          </div>
        </div>
      </div>
      <div className={`navbar navbar--${breakpointWidth > 901 ? 'left' : 'bottom'}`}>
        <div className="navbar-menu">
          <a className="nav-item nav-item--fake">
            <div className="nav-item-placeholder" />
          </a>
          <a className="nav-item nav-item--fake">
            <div className="nav-item-placeholder" />
          </a>
          <a className="nav-item nav-item--fake">
            <div className="nav-item-placeholder" />
          </a>
          <a className="nav-item nav-item--fake">
            <div className="nav-item-placeholder" />
          </a>
        </div>
      </div>
      <div className="page-content">
        {error ? (
          <div className="page-content-message">
            <div className="message message--error">{t('error.general')}</div>
            <div className="page-content-message-link">
              <a href="/logout" className="button button--small">
                <i className="fa fa-sign-out-alt" />
                {t('nav.logout')}
              </a>
            </div>
          </div>
        ) : (
          <div className="loader">
            <i className="fa fa-spin fa-spinner" />
          </div>
        )}
      </div>
    </div>
  )
}
