import React, { Component } from 'react'

export default function Submit(props) {
  let {
    text = false,
    icon = false,
    disabled = false,
    classes = [],
    buttonClasses = [],
    children,
    size = 'default',
    color = 'blue',
    align = 'left',
  } = props

  return (
    <div className={`form-submit form-submit--${size} form-submit--${align} ${classes.join(' ')}`}>
      <div className="form-submit-button">
        <button
          className={`button button--${size} button--${color} ${buttonClasses.join(' ')}`}
          type="submit"
          disabled={disabled}>
          {icon ? <i className={`fa fa-${icon}`} /> : null}
          {text}
        </button>
      </div>
      <div className="form-submit-content">{children}</div>
    </div>
  )
}
