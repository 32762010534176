import React, { Component, PureComponent, Fragment } from 'react'
import { Form, Submit, Field } from '../form'

export default function TermOverlay(props) {
  let { acceptTermAction, acceptTermLoading, acceptTerm, t } = props

  let { hasAcceptedTerms = false, term } = acceptTermAction

  return (
    <div className="term-overlay">
      <div className="term-overlay-inner">
        <div className="term-overlay-header">
          <h2>{hasAcceptedTerms ? t('term.updateTitle') : t('term.acceptTitle')}</h2>
          <p>{t('term.description')}</p>
        </div>
        <div className="term-overlay-content">
          <div className="styled-content" dangerouslySetInnerHTML={{ __html: term.content }} />
        </div>
        <div className="term-overlay-action">
          {acceptTermLoading ? (
            <i className="fa fa-spin fa-spinner" />
          ) : (
            <Form onSubmit={() => acceptTerm(term.id)} validation={{ accept: ['required'] }}>
              <Field
                type="checkbox"
                inputLabel={t('term.acceptText')}
                link={{
                  url: 'https://www.trustmary.com/privacy-policy/',
                  text: 'Privacy Policy',
                }}
                name="accept"
              />
              <Submit className="button" text={t('term.acceptButton')} />
            </Form>
          )}
        </div>
      </div>
    </div>
  )
}
