import FieldsProvider from 'appField/fieldStore'
import { ResponsiveProvider } from 'common/responsiveStore'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'
import TagProvider from 'tag/tagStore'
import { ApiProvider } from './api'
import { AuthProvider } from './auth/authStore'
import { TranslationProvider } from './i18n'
import Meta from './meta'
import Redirect from './redirect'

const Login = lazy(() => import('./auth/login'))
const Register = lazy(() => import('./auth/register'))
const Token = lazy(() => import('./auth/token'))
const SsoRedirect = lazy(() => import('./sso/page'))
const Logout = lazy(() => import('./auth/logout'))

const Unsubscribe = lazy(() => import('./unsubscribe/unsubscribe'))
const Notification = lazy(() => import('./unsubscribe/notification'))
const Dashboard = lazy(() => import('./dashboard/dashboardRoutes'))
const Approve = lazy(() => import('./approve'))
const Start = lazy(() => import('./start/page'))
const EmailVerification = lazy(() => import('./send/emailVerification'))
const PublicReport = lazy(() => import('./report/public'))
const Onboard = lazy(() => import('./onboard'))

const providers = [ResponsiveProvider, TranslationProvider, ApiProvider, AuthProvider, TagProvider, FieldsProvider]

const app = (
  <Suspense
    fallback={
      <div className="loader">
        <i className="fa fa-spin fa-spinner" />
      </div>
    }>
    <Redirect>
      <Meta />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/login/token" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/sso/:service" component={SsoRedirect} />
        <Route path="/token" component={Token} />
        <Route path="/logout" component={Logout} />
        <Route path="/approve/:approve_id" component={Approve} />
        <Route path="/unsubscribe" component={Unsubscribe} />
        <Route path="/notification" component={Notification} />
        <Route path="/start" component={Start} />
        <Route path="/onboard" component={Onboard} />
        <Route path="/email-verification/:status" component={EmailVerification} />
        <Route path="/r/:report_id" component={PublicReport} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </Redirect>
  </Suspense>
)

function connectProviders(providers, children) {
  let [provider, ...rest] = providers
  return React.createElement(provider, null, rest.length ? connectProviders(rest, children) : children)
}

export default connectProviders(providers, app)
