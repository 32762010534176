import React, { Component, PureComponent, Fragment } from 'react'
import { CountryDropdown } from 'react-country-region-selector'

export default function CountryField({ name, id, onChange, value, t }) {
  return (
    <div className="form-field form-field--select">
      <CountryDropdown
        valueType="short"
        value={value}
        id={id}
        defaultOptionLabel={t('common.selectCountry')}
        priorityOptions={['FI', 'SE', 'US']}
        onChange={value => onChange({ name, value })}
      />
    </div>
  )
}
