import React, { Component, PureComponent, Fragment } from 'react'

export default class Redirect extends Component {
  constructor(props) {
    super(props)

    let { hostname, href } = window.location

    this.state = {
      redirect: hostname.endsWith('kokemuksia.fi') ? href.replace('kokemuksia.fi', 'trustmary.com') : false,
    }
  }

  componentDidMount() {
    let { redirect } = this.state
    if (typeof redirect === 'string') window.location.replace(redirect)
  }

  render() {
    if (this.state.redirect)
      return (
        <div className="fullpage">
          <div className="fullpage-inner">
            <i className="fa fa-spin fa-circle-notch" />
          </div>
        </div>
      )

    return this.props.children
  }
}
