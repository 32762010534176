import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

interface ResponsiveContextType {
  breakpoint: string
  breakpointWidth: number
  breakpointClasses: string[]
}

/* Store for responsive */
export const ResponsiveContext = createContext<ResponsiveContextType>({} as never)
export const ResponsiveStore = ResponsiveContext.Consumer

const calculateBreakpoint = (width: number): [string, number, string[]] => {
  if (width < 350) return ['tiny', 350, ['under-large', 'under-medium', 'under-small', 'under-minimum', 'under-tiny']]

  if (width < 400) return ['minimum', 400, ['under-large', 'under-medium', 'under-small', 'under-minimum', 'over-tiny']]

  if (width < 600) return ['small', 600, ['under-large', 'under-medium', 'under-small', 'over-minimum', 'over-tiny']]

  if (width < 900) return ['medium', 900, ['under-large', 'under-medium', 'over-small', 'over-minimum', 'over-tiny']]

  if (width < 1450) return ['large', 1450, ['under-large', 'over-medium', 'over-small', 'over-minimum', 'over-tiny']]

  return ['maximum', 4000, ['over-large', 'over-medium', 'over-small', 'over-minimum', 'over-tiny']]
}

export const ResponsiveProvider = ({ children }: { children: React.ReactNode }) => {
  const [initialBreakpoint, initialBreakpointWidth, initialBreakpointClasses] = calculateBreakpoint(window.innerWidth)
  const [breakpoint, setBreakpoint] = useState<string>(initialBreakpoint)
  const [breakpointWidth, setBreakpointWidth] = useState<number>(initialBreakpointWidth)
  const [breakpointClasses, setBreakpointClasses] = useState<string[]>(initialBreakpointClasses)

  const handleResize = () => {
    const [updatedBreakpoint, breakpointWidth, breakpointClasses] = calculateBreakpoint(window.innerWidth)

    setBreakpoint(updatedBreakpoint)
    setBreakpointWidth(breakpointWidth)

    // TODO: This will cause unnecessary rerenders as the array instance changes
    setBreakpointClasses(breakpointClasses)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const contextValue: ResponsiveContextType = useMemo(
    () => ({
      breakpoint,
      breakpointWidth,
      breakpointClasses,
    }),
    [breakpoint, breakpointWidth, breakpointClasses]
  )

  return (
    <ResponsiveContext.Provider value={contextValue}>
      <div className={breakpointClasses.map(s => `breakpoint-${s}`).join(' ')}>{children}</div>
    </ResponsiveContext.Provider>
  )
}

export function connectResponsive<T>(component: React.FC<T>): (props: T) => JSX.Element {
  return props => <ResponsiveStore>{state => React.createElement(component, { ...props, ...state })}</ResponsiveStore>
}

export function useResponsive() {
  return useContext(ResponsiveContext)
}
