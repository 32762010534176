import axios from 'axios'
import uncaught from 'uncaught'

window.catchError = err => {
  console.log('Catched error!')
  console.log(err)
}

uncaught.start()
uncaught.addListener(window.catchError)
