import React from 'react'

export default function IconSelect(props) {
  let { name, value, onChange, options } = props

  return (
    <div className="form-field form-field--iconselect form-iconselect">
      {options.map(option => {
        let { label, value: optionValue, description, icon } = option

        let isSelected = optionValue === value
        let classes = ['form-iconselect-item']
        if (isSelected) classes.push('form-iconselect-item--selected')

        return (
          <div className="form-iconselect-item-wrapper" key={optionValue}>
            <button
              className={classes.join(' ')}
              onClick={e => e.preventDefault() & onChange({ name, value: optionValue })}>
              <div className="form-iconselect-item-inner">
                <div className="form-iconselect-item-icon">
                  <i className={`fa fa-${icon}`} />
                </div>
                <div className="form-iconselect-item-label">{label}</div>
                <div className="form-iconselect-item-description">{description}</div>
              </div>
            </button>
          </div>
        )
      })}
    </div>
  )
}
