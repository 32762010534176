import React, { useEffect } from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { track } from './track'

const Inner = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation()
  useEffect(() => {
    track('ROUTER_PAGE_CHANGE', { pathname: location.pathname })
  }, [location.pathname])
  return <>{children}</>
}

export const Router = ({ children }: { children: React.ReactNode }) => {
  return (
    <BrowserRouter>
      <Inner>{children}</Inner>
    </BrowserRouter>
  )
}
