import { WindowGlobals } from './window'

export type TrackEvent =
  | 'WIDGET_CREATE'
  | 'WIDGET_COPY_SCRIPT'
  | 'WIDGET_COPY_DIV_ELEMENT'
  | 'WIDGET_MANUAL_TESTIMONIAL_SELECT'
  | 'WIDGET_SETTINGS_CHANGE'
  | 'WIDGET_TOGGLE_ACTIVE'
  | 'WIDGET_INTEGRATION_CLICK'
  | 'WIDGET_SEND_NOTIFICATION_TO_USERS'
  | 'FOLDER_CREATE'
  | 'FOLDER_ADD'
  | 'FOLDER_REMOVE'
  | 'CONVERSION_CREATE'
  | 'SURVEY_CREATE'
  | 'SURVEY_COPY_LINK'
  | 'SURVEY_EDITOR_ACTION'
  | 'REVIEW_IMPORT'
  | 'REVIEW_CREATE'
  | 'REVIEW_PUBLISH'
  | 'BILLING_CHANGE_CREDIT_CARD'
  | 'BILLING_REMOVE_CREDIT_CARD'
  | 'BILLING_UPDATE_PLAN'
  | 'BILLING_CANCEL_PLAN'
  | 'SETTINGS_SAVE_COMPANY_INFO'
  | 'SETTINGS_ADD_USER'
  | 'SETTINGS_REMOVE_USER'
  | 'SETTINGS_ADD_EMAIL_SENDER'
  | 'SETTINGS_REMOVE_EMAIL_SENDER'
  | 'SETTINGS_EDIT_THEME'
  | 'SETTINGS_ADD_APIKEY'
  | 'SETTINGS_REMOVE_APIKEY'
  | 'SETTINGS_ADD_WEBHOOK'
  | 'SETTINGS_REMOVE_WEBHOOK'
  | 'SETTINGS_EDIT_WEBHOOK'
  | 'ROUTER_PAGE_CHANGE'
  | 'FREEMIUM_REGISTER'
  | 'REGISTER'
  | 'ONBOARD_ORGANIZATION'
  | 'ONBOARD_END'
  | 'ONBOARD_DONE'
  | 'USER_20S_ACTIVE'
  | 'HAS_WIDGET_EVENT'
  | 'HAS_SURVEY_EVENT'

export const dataLayerPush = (event: TrackEvent, options?: Record<string, unknown>) => {
  const globalWindow = window as unknown as WindowGlobals
  // Send event to Google Tag Manager
  globalWindow.dataLayer = globalWindow.dataLayer || []
  globalWindow.dataLayer.push({
    event: `trustmary.${event}`,
    ...options,
  })
}

/**
 * Track UI iniated events. Sends them to Mixpanel and GTM
 */
export const track = (event: TrackEvent, options?: Record<string, unknown>) => {
  const mixpanel = (window as unknown as WindowGlobals).mixpanel
  if (mixpanel) {
    // Send event to Mixpanel
    mixpanel.track(event, options)
  }

  dataLayerPush(event, options)
}
