import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { uploadFile } from 'form/uploadFile'

const toolbars = {
  full: {
    menubar: 'insert format tools table',
    plugins: 'colorpicker code image lists autoresize link paste',
    toolbar:
      'formatselect | fontsizeselect | image | bold italic link | \
       alignleft aligncenter alignright | \
       bullist numlist mediaembed | removeformat',
    menu: {
      format: {
        title: 'Format',
        items: 'underline strikethrough fontformats forecolor backcolor',
      },
    },
    content_style: 'p{margin:0;}',
    file_picker_types: 'image',
    file_picker_callback: (cb, value, meta) => {
      let input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')

      input.onchange = function () {
        let file = this.files[0]
        uploadFile(null, file).then(fileUrl => cb(fileUrl, { title: file.name }))
      }

      input.click()
    },
  },
  inline: {
    plugins: 'colorpicker autoresize paste',
    toolbar: 'bold italic',
    forced_root_block: 'div',
    menubar: false,
    statusbar: false,
    content_style: 'p{margin:0;}',
  },
}

export default function WysiwygField({ value, name, toolbar = 'full', onChange }) {
  const editorRef = useRef(null)

  const onBlur = e => {
    if (editorRef.current) {
      const content = editorRef.current.getContent()
      editorRef.current.setDirty(false)
      onChange({ name, value: content })
    }
  }
  return (
    <div className="field field--wysiwyg">
      <Editor
        initialValue={value}
        onInit={(evt, editor) => (editorRef.current = editor)}
        onBlur={onBlur}
        tinymceScriptSrc="https://d2nce6johdc51d.cloudfront.net/tinymce/tinymce.min.js"
        init={toolbars[toolbar] || toolbars.full}
      />
    </div>
  )
}
