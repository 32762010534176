import React, { Component, PureComponent, Fragment } from 'react'
import TranslationStore from 'i18n'
import Helmet from 'react-helmet'

function MetaInfo({ lang }) {
  let rootTitle = 'Trustmary Dashboard'

  if (lang === 'se') lang = 'sv'

  return (
    <Helmet defaultTitle={rootTitle} titleTemplate={`%s - ${rootTitle}`}>
      <html lang={lang} />
      <link rel="icon" href="https://d2nce6johdc51d.cloudfront.net/img/trustmary-favicon.png" sizes="72x72" />
    </Helmet>
  )
}

export default function MetaInfoWrapper() {
  return <TranslationStore>{i18nState => <MetaInfo {...i18nState} />}</TranslationStore>
}
