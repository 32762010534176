// Sentry logging
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
const { SENTRY_DSN, SENTRY_ENV } = window

if (!!SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
  })
}
