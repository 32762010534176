import React, { Component, useRef, useMemo, useState, useEffect } from 'react'
import { Map, List } from 'immutable'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import './conditions.sass'

const operators = ['is', 'isnot', 'greaterthan', 'lessthan', 'istrue', 'isfalse', 'isnull', 'notnull']
const npsFieldOperators = ['is', 'isnot', 'greaterthan', 'lessthan']
const disableOperatorField = ['istrue', 'isfalse', 'isnull', 'notnull']
const npsValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

function OperatorField({ operator, test, name, onChange, field, t }) {
  if (!field) return null

  let { type, options = [] } = field

  if (disableOperatorField.includes(operator) || !type) return null

  if (type === 'nps' && npsFieldOperators.includes(operator)) {
    return (
      <div className="conditions-item-input-test conditions-item-input-test--nps">
        <select name={name} onChange={e => onChange({ name, value: parseInt(e.target.value) })} value={test.toString()}>
          {!test ? <option value="">{t('conditions.field.chooseValue')}</option> : null}
          {npsValues.map(nps => (
            <option value={nps} key={nps}>
              {nps}
            </option>
          ))}
        </select>
      </div>
    )
  }

  if (type === 'radios' || type === 'checkboxes') {
    return (
      <div className="conditions-item-input-test conditions-item-input-test--select">
        <select name={name} onChange={onChange} value={test}>
          {!test ? <option value="">{t('conditions.field.chooseValue')}</option> : null}
          {options.map(({ value, label }) => {
            // If label is array, it's made of translation string, so we translate them
            if (Array.isArray(label)) label = label.map(i => t(i)).join(' ')

            return (
              <option value={value} key={value}>
                {label}
              </option>
            )
          })}
        </select>
      </div>
    )
  }

  return (
    <div className="conditions-item-input-test conditions-item-input-test--text">
      <input
        type="text"
        name={name}
        onChange={onChange}
        value={test || ''}
        placeholder={t('conditions.field.inputPlaceholder')}
      />
    </div>
  )
}

const Handle = SortableHandle(props => (
  <div className="conditions-handle">
    <i className="fa fa-bars" />
  </div>
))

const ConditionItem = SortableElement(props => {
  let { condition, onChange, fields, onRemove, itemIndex: index, t } = props
  let fieldValue = condition.get('field', '')
  let field = fields.find(f => f.value === fieldValue)

  return (
    <div className="conditions-item">
      <div className="conditions-item-handle">
        <Handle />
      </div>
      <div className="conditions-item-input">
        <div className="conditions-item-input-row">
          {index ? (
            <div className="conditions-item-input-join">
              <select value={condition.get('join')} name={`${index}.join`} onChange={onChange}>
                <option value="and">{t('conditions.field.and')}</option>
                <option value="or">{t('conditions.field.or')}</option>
              </select>
            </div>
          ) : null}
          <div className="conditions-item-input-field">
            <select value={fieldValue} name={`${index}.field`} onChange={onChange}>
              {!fieldValue ? <option value="">{t('conditions.field.chooseField')}</option> : null}
              {fields.map(({ label, value, level, title }, key) => {
                level = level === 2 ? '-- ' : level === 1 ? '- ' : ''

                // If label is array, it's made of translation string, so we translate them
                if (Array.isArray(label)) label = label.map(i => t(i)).join(' ')

                if (Array.isArray(title)) title = title.map(i => t(i)).join(' ')

                if (title)
                  return (
                    <option value="" disabled key={key}>
                      {level}
                      {title}
                    </option>
                  )

                return (
                  <option value={value} key={key}>
                    {level}
                    {label}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className="conditions-item-input-row">
          <div className="conditions-item-input-operator">
            <select value={condition.get('operator')} name={`${index}.operator`} onChange={onChange}>
              {operators.map(operator => (
                <option value={operator} key={operator}>
                  {t(`conditions.operators.${operator}`)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="conditions-item-input-row">
          <OperatorField
            operator={condition.get('operator')}
            test={condition.get('test', '')}
            name={`${index}.test`}
            field={field}
            t={t}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="conditions-item-remove">
        <button onClick={e => e.preventDefault() & onRemove(index)}>
          <i className="fa fa-trash" />
        </button>
      </div>
    </div>
  )
})

const ConditionsList = SortableContainer(({ conditions, onChange, onRemove, fields, t }) => {
  return (
    <div className="conditions-list">
      {conditions.map((condition, index) => (
        <ConditionItem
          key={index}
          index={index}
          itemIndex={index}
          condition={condition}
          onChange={onChange}
          onRemove={onRemove}
          fields={fields}
          t={t}
        />
      ))}
    </div>
  )
})

export default function ConditionsField(props) {
  let { value, name, fields = [], t, disabled } = props

  if (!List.isList(value)) value = List()

  let parentRef = useRef()

  const handleConditionChange = e => {
    let { name: inputName, value: inputValue } = e.target || e
    let { value, name, onChange } = props

    if (!List.isList(value)) value = List()

    onChange({
      name,
      value: value.setIn(inputName.split('.'), inputValue),
    })
  }

  const handleCreate = join => {
    let { value, name, onChange } = props
    if (!List.isList(value)) value = List()

    onChange({ name, value: value.push(Map().set('join', join).set('operator', 'is')) })
  }

  const removeCondition = index => {
    let { value, name, onChange } = props
    onChange({
      name,
      value: value.remove(index),
    })
  }

  const handleReorder = ({ oldIndex, newIndex }) => {
    let { value, name, onChange } = props
    let source = value.get(oldIndex)

    value = value
      .setIn([oldIndex, '_delete'], true) // Flag source to be deleted
      .insert(oldIndex > newIndex ? newIndex : newIndex + 1, source)
      .filter(s => s.get('_delete', false) === false) // Remove item with _delete flag

    onChange({ name, value })
  }

  return (
    <div className={`form-field form-field--conditions ${disabled ? 'form-field--disabled' : ''}`} ref={parentRef}>
      {value.size ? (
        <div className="conditions conditions--edit">
          <ConditionsList
            conditions={value}
            onChange={handleConditionChange}
            onRemove={removeCondition}
            onSortEnd={handleReorder}
            name={name}
            fields={fields}
            useDragHandle
            t={t}
          />
          <div className="conditions-create">
            <div className="conditions-create-button">
              <button onClick={e => e.preventDefault() & handleCreate('and')}>
                <span>+</span> {t('conditions.field.and')}
              </button>
              <button onClick={e => e.preventDefault() & handleCreate('or')}>
                <span>+</span> {t('conditions.field.or')}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="conditions condition--empty">
          <div className="conditions-create">
            <div className="conditions-create-button">
              <button onClick={e => e.preventDefault() & handleCreate('and')}>
                <span>+</span> {t('conditions.field.createFirst')}
              </button>
            </div>
          </div>
        </div>
      )}
      {disabled && <div className="form-field-overlay" />}
    </div>
  )
}
