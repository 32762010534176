import axios from 'axios'
import { List, fromJS } from 'immutable'
import { request } from 'api'

/**
 * Normally we get api from react context, but with wysiwyg we can't do that
 * So here we create a fallback fileApi object that uses appApi posting
 */
const fileApi = {
  post: (path, data) => request('POST', APP_API_ROOT + path, data, false),
  uploadFilePath: '/file',
}

export function uploadFiles(api, files) {
  if (!List.isList(files)) return Promise.resolve(List())

  return Promise.all(files.toJS().map(file => uploadFile(api, file))).then(files => fromJS(files))
}

export function uploadFile(api, file) {
  api = api || fileApi // Default to fallback fileApi
  let { uploadFilePath = '/upload-file' } = api

  if (typeof file === 'string') return Promise.resolve(file)

  return api
    .post(uploadFilePath, { filename: file.name, type: file.type, size: file.size })
    .then(({ data }) => data.data || {})
    .then(({ postData, filename, fileurl }) => {
      let formData = new FormData()
      for (let key in postData.fields) {
        formData.append(key, postData.fields[key])
      }

      formData.append('file', file, filename)

      return axios({
        method: 'POST',
        url: postData.url,
        data: formData,
      }).then(res => fileurl)
    })
}

export function uploadPrivateFile(api, file) {
  return api
    .post(
      `/file/private`,
      { filename: file.name, type: file.type, size: file.size },
      { timestamp: new Date().getTime() }
    )
    .then(({ data }) => data.data || {})
    .then(({ postData, filename, fileurl, fileId }) => {
      let formData = new FormData()
      for (let key in postData.fields) {
        formData.append(key, postData.fields[key])
      }

      formData.append('file', file, filename)

      return axios({
        method: 'POST',
        url: postData.url,
        data: formData,
      }).then(res => ({ fileurl, fileId }))
    })
}
