import axios from 'axios'
import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/sv'
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import AuthLoader from './auth/loader'
import localEn from './translations/en.json'
import localFi from './translations/fi.json'

const TRANSLATIONS_URL = 'https://d3ingpyowh9rb.cloudfront.net/translations.json'

export interface TranslationContextType {
  t: Translator
  setLanguage: (lang: string) => void
  lang: string
  languages: { [key: string]: string }
  appLanguages: { [key: string]: string }
  brand: string
  locale: string
}

export const TranslationContext = createContext<TranslationContextType>({
  t: () => '',
  setLanguage: () => 0,
  lang: '',
  languages: {},
  appLanguages: {},
  brand: 'trustmary',
  locale: '',
})

const TranslationStore = TranslationContext.Consumer
export default TranslationStore

interface Translation {
  [key: string]: string
}

interface Translations {
  [language: string]: Translation[]
}

export type Translator = (key: string, replacements?: string[], overrideLang?: string) => string

const defaultLang = 'en'
export const languages: { [key: string]: string } = {
  fi: 'Suomeksi',
  en: 'In English',
  se: 'På Svenska',
  fr: 'En français',
  es: 'in Spanisch',
  de: 'auf Deutsch',
}

export const appLanguages: { [key: string]: string } = {
  fi: 'Suomeksi',
  en: 'In English',
}

export const messageLangs: string[] = ['fi', 'en', 'se']

async function loadTranslations(): Promise<Translations> {
  return axios.get<Translations>(TRANSLATIONS_URL).then(({ data }) => {
    data = data || {}
    data.fi = Object.assign(data.fi, localFi)
    data.en = Object.assign(data.en, localEn)
    return data
  })
}

const urlQuery: { [key: string]: string } = window.location.search
  .substr(1)
  .split('&')
  .reduce((mapped, str) => {
    const [key, value] = str.split('=')
    mapped[key] = value
    return mapped
  }, {})

function getBrowserLang(): string | undefined {
  if (!navigator.language) return
  const [browserLang] = navigator.language.split('-')
  return appLanguages[browserLang] ? browserLang : undefined
}

const locale = window.navigator.language

export function TranslationProvider({ children }: { children?: ReactNode }): JSX.Element {
  const [lang, setLanguage] = useState<string>(
    () => urlQuery['lang'] || (localStorage['_lang'] as string) || getBrowserLang() || 'en'
  )
  const [translations, setTranslations] = useState<Translations>()

  useEffect(() => {
    void (async () => {
      moment.locale(locale)
      const translations = await loadTranslations()
      setTranslations(translations)
    })()
  }, [])

  useEffect(() => {
    localStorage._lang = lang
  }, [lang])

  const t = useCallback<Translator>(
    (key, replacements, overrideLang): string => {
      if (!translations) return ''
      const l = overrideLang || lang
      let value = (translations[l]?.[key] as string) || false

      value = value || (translations[defaultLang][key] as string) || key

      if (Array.isArray(replacements) && replacements.length)
        value = replacements.reduce(
          (value, replacement, key) => value.replace(new RegExp(`:${key}`, 'g'), replacement),
          value
        )

      return value
    },
    [lang, translations]
  )

  const contextValue: TranslationContextType = {
    t,
    setLanguage,
    lang,
    languages,
    appLanguages,
    brand: 'trustmary',
    locale,
  }

  if (!translations) return <AuthLoader t={t} />

  return <TranslationContext.Provider value={contextValue}>{children}</TranslationContext.Provider>
}

export function connectI18n<T>(component: React.FC<T>): (props: T) => JSX.Element {
  return props => (
    <TranslationStore>{i18nState => React.createElement(component, { ...props, ...i18nState })}</TranslationStore>
  )
}

export function useI18n() {
  return useContext(TranslationContext)
}
