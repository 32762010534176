import React, { Component, PureComponent, Fragment } from 'react'

export default function SelectField(props) {
  let {
    name,
    options = [],
    value,
    defaultValue,
    id,
    onChange,
    t,
    selectText = 'common.selectValue',
    removeText = 'common.emptyValue',
    removable = false,
    disabled = false,
  } = props

  return (
    <div className="form-field form-field--select">
      <select onChange={onChange} value={value || defaultValue || ''} name={name} id={id} disabled={disabled}>
        <option value="" disabled>
          {t(selectText)}
        </option>
        {value && removable ? <option value="">({t(removeText)})</option> : null}
        {options.map(({ value: optionValue, label, options }) => {
          /* If options is array, this is option group */
          if (!optionValue && Array.isArray(options))
            return (
              <optgroup label={label} key={label}>
                {options.map(o => (
                  <option value={o.value} key={o.value || o.label}>
                    {o.label}
                  </option>
                ))}
              </optgroup>
            )

          return (
            <option value={optionValue} key={optionValue || label}>
              {label}
            </option>
          )
        })}
      </select>
    </div>
  )
}
