import axios, { AxiosError, AxiosResponse, Method } from 'axios'
import jwtDecode from 'jwt-decode'

interface IToken {
  current: string | false
  expire: number
}

const apiToken: IToken = {
  current: false,
  expire: 0,
}

export const setApiToken = (token: string) => {
  if (!token) return
  try {
    const data: { exp: number } = jwtDecode(token)
    apiToken.expire = data.exp * 1000
    apiToken.current = token
  } catch (err) {
    console.log(err)
  }
}

interface UpdateTokenHeaders {
  'update-token'?: string
}

function refreshToken() {
  return axios({
    method: 'POST',
    url: '/api/auth',
    headers: {
      Authorization: `Bearer ${apiToken.current || ''}`,
    },
  }).then(res => {
    const headers = res.headers as UpdateTokenHeaders
    if (headers['update-token']) setApiToken(headers['update-token'])
    return res
  })
}

interface ApiError extends AxiosError {
  _apierror?: boolean
}

export function request<Response, Body = unknown>(
  method: Method,
  url: string,
  data?: Body,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logoutOn403 = true
): Promise<AxiosResponse<Response>> {
  const headers: { [name: string]: string } = {}

  if (apiToken.current) headers.Authorization = `Bearer ${apiToken.current}`

  const expiredToken = !!apiToken.expire && new Date().getTime() > apiToken.expire

  const prefetch = expiredToken ? refreshToken() : Promise.resolve()

  const params = {
    method,
    url,
    headers,
    data: data || {},
  }

  return prefetch
    .then(() => axios(params))
    .then(res => {
      const headers = res.headers as UpdateTokenHeaders
      if (headers['update-token']) setApiToken(headers['update-token'])
      return res as AxiosResponse<Response>
    })
    .catch((err: ApiError) => {
      // if (logoutOn403 && err.request && err.request.status === 403) window.location.href = '/logout'
      console.log(err)

      err._apierror = true
      throw err
    })
}
