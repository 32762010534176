import {
  AppFieldItem,
  CreateAppFieldItem,
  CreateFieldBody,
  CreateFieldResult,
  DeleteFieldResult,
  FieldEntityType,
  GetFieldsResult,
  UpdateFieldBody,
  UpdateFieldResult,
} from '@tm/types/common/field'
import { Map } from 'immutable'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useApi } from '../api'

export interface FieldsContextType {
  fields?: AppFieldItem[]
  updateField: (field: AppFieldItem) => Promise<boolean | undefined>
  createField: (field: CreateAppFieldItem) => Promise<boolean | undefined>
  deleteField: (field: AppFieldItem) => Promise<boolean | undefined>
  restoreField: (field: AppFieldItem) => Promise<boolean | undefined>
  loadFields: () => Promise<void>
  loading: boolean
}

export interface UseFieldsResponse {
  fields: AppFieldItem[]
  updateField: (field: AppFieldItem) => Promise<boolean | undefined>
  createField: (field: CreateAppFieldItem) => Promise<boolean | undefined>
  deleteField: (field: AppFieldItem) => Promise<boolean | undefined>
  restoreField: (field: AppFieldItem) => Promise<boolean | undefined>
  loading: boolean
}

export const FieldsContext = createContext<FieldsContextType>({} as never)

export function useFields(entities?: string[], deleted?: boolean): UseFieldsResponse {
  const ctx = useContext(FieldsContext)
  useEffect(() => {
    if (!ctx.fields && !ctx.loading) {
      void ctx.loadFields()
    }
  }, [ctx])

  const filteredFields = useMemo(() => {
    let res = ctx.fields || []
    if (entities !== undefined) {
      res = res.filter(field => entities.some((entity: FieldEntityType) => field.entities.includes(entity)))
    }
    if (deleted !== undefined) {
      res = res.filter(field => !!field.deleted_at === deleted)
    }
    return res
  }, [ctx.fields, entities, deleted])

  return useMemo(() => {
    return {
      ...ctx,
      fields: filteredFields,
    }
  }, [ctx, filteredFields])
}

export default function FieldsProvider({ children }: { children: React.ReactNode }) {
  const [fieldsById, setFieldsById] = useState<Map<string, AppFieldItem>>()
  const [loading, setLoading] = useState(false)
  const { appApi } = useApi()
  const fieldsLoadingRef = useRef<boolean>(false)

  const loadFields = useCallback(async () => {
    if (fieldsLoadingRef.current) return
    fieldsLoadingRef.current = true
    setLoading(true)
    try {
      const { data } = await appApi.get<GetFieldsResult>(`/field`)
      const mappedFields = data.fields.reduce((acc, field) => {
        return acc.set(field.id, field)
      }, Map<string, AppFieldItem>())
      setFieldsById(mappedFields)
    } finally {
      fieldsLoadingRef.current = false
      setLoading(false)
    }
  }, [loading, setLoading])

  const updateField = useCallback(
    async (field: AppFieldItem) => {
      if (loading) return Promise.resolve(undefined)
      setLoading(true)
      try {
        const { data } = await appApi.patch<UpdateFieldResult, UpdateFieldBody>(`/field/${field.id}`, field)
        const updatedField = data.field
        setFieldsById((fieldsById || Map<string, AppFieldItem>()).set(updatedField.id, updatedField))
        return true
      } catch (error) {
        console.log(error)
        return false
      } finally {
        setLoading(false)
      }
    },
    [fieldsById, setFieldsById, loading, setLoading]
  )

  const createField = useCallback(
    async (field: CreateAppFieldItem) => {
      if (loading) return Promise.resolve(undefined)
      setLoading(true)
      try {
        const { data } = await appApi.post<CreateFieldResult, CreateFieldBody>('/field', field)
        const createdField = data.field
        setFieldsById((fieldsById || Map<string, AppFieldItem>()).set(createdField.id, createdField))
        return true
      } finally {
        setLoading(false)
      }
    },
    [fieldsById, setFieldsById, loading, setLoading]
  )

  const deleteField = useCallback(
    async (field: AppFieldItem) => {
      if (loading) return Promise.resolve(undefined)
      setLoading(true)
      try {
        await appApi.delete<DeleteFieldResult>(`/field/${field.id}`)
        setFieldsById((fieldsById || Map<string, AppFieldItem>()).set(field.id, { ...field, deleted_at: new Date() }))
        return true
      } finally {
        setLoading(false)
      }
    },
    [fieldsById, setFieldsById, loading, setLoading]
  )

  const restoreField = useCallback(
    async (field: AppFieldItem) => {
      if (loading) return Promise.resolve(undefined)
      setLoading(true)
      try {
        await appApi.post(`/field/${field.id}/restore`)
        setFieldsById((fieldsById || Map<string, AppFieldItem>()).set(field.id, { ...field, deleted_at: null }))
        return true
      } finally {
        setLoading(false)
      }
    },
    [fieldsById, setFieldsById, loading, setLoading]
  )

  const fields = useMemo(() => (fieldsById ? Array.from(fieldsById.values()) : undefined), [fieldsById])

  const contextValue = useMemo((): FieldsContextType => {
    return {
      updateField,
      createField,
      deleteField,
      restoreField,
      loadFields,
      fields,
      loading,
    }
  }, [updateField, createField, deleteField, restoreField, loadFields, loading, fields])

  return <FieldsContext.Provider value={contextValue}>{children}</FieldsContext.Provider>
}
