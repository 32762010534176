import React from 'react'
import { List } from 'immutable'
import './repeater.sass'

const utm_keys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

function UtmInput({ value, onChange }) {
  let index = value.indexOf('=')
  let utm_key = value.substr(0, index)
  let utm_value = value.substr(index + 1)
  return (
    <div className="form-repeater-utm">
      <div className="form-field form-field--select">
        <select onChange={e => onChange(`${e.target.value}=${utm_value}`)} value={utm_key}>
          <option value="">-</option>
          {utm_keys.map(utm_key => (
            <option key={utm_key} value={utm_key}>
              {utm_key}
            </option>
          ))}
        </select>
      </div>
      <input type="text" value={utm_value} onChange={e => onChange(`${utm_key}=${e.target.value}`)} />
    </div>
  )
}

export default function RepeaterInput(props) {
  let { value, name, onChange, t, repeaterType = 'text' } = props

  if (!List.isList(value)) value = List().push('')

  const updateItem = (key, inputValue) =>
    onChange({
      name,
      value: value.set(key, inputValue),
    })

  const removeItem = key =>
    onChange({
      name,
      value: value.remove(key),
    })

  const addItem = () =>
    onChange({
      name,
      value: value.push(''),
    })

  return (
    <div className="form-field form-field--repeater">
      <div className="form-repeater-fields">
        {value.map((inputValue, key) => {
          return (
            <div className="form-repeater-field" key={key}>
              {repeaterType === 'utm' ? (
                <UtmInput value={inputValue} onChange={value => updateItem(key, value)} />
              ) : (
                <input type="text" value={inputValue} onChange={e => updateItem(key, e.target.value)} />
              )}
              <div className="form-repeater-field-remove">
                <button onClick={e => e.preventDefault() & removeItem(key)}>
                  <i className="fa fa-trash" />
                </button>
              </div>
            </div>
          )
        })}
      </div>
      <div className="form-repeater-action">
        <button className="button button--small button--grey" onClick={e => e.preventDefault() & addItem()}>
          <i className="fa fa-plus" />
          {t('common.add')}
        </button>
      </div>
    </div>
  )
}
