import React, { Component, PureComponent, Fragment } from 'react'
import { Map, List, fromJS } from 'immutable'

const formatBytes = (a, b) => {
  if (0 == a) return '0 B'

  let c = 1024,
    d = b || 2,
    e = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c))

  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}

const getExtension = name => {
  let parts = name.split('.')
  return parts[parts.length - 1]
}

const getFileIcon = extension => {
  if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) return 'image'

  if (['csv', 'xls', 'xlsx'].includes(extension)) return 'table'

  return 'question'
}

function BoxFile(props) {
  let {
    onFileChange,
    onFileRemove,
    name,
    value,
    t,
    fileType = '.jpg,.jpeg,.png,.gif,.mp4,.mpeg,.doc,.docx,.pdf,.xls,xlsx,.csv,.txt,.zip',
    maxSize = 10485760,
  } = props

  let multiple = false
  let defaultData = { name, fileType, multiple, maxSize }

  if (typeof value === 'string' && value.length) value = List().push(value)

  if (!List.isList(value)) value = List()

  let file = value.first()

  if (typeof file === 'string') file = { preview: file, name: file.split('/').pop() }

  let hasFile = file && file.preview

  return (
    <div className="form-field-box-file">
      {hasFile ? (
        <div className="form-field-box-file-preview">
          {file.preview ? (
            <img src={file.preview} alt={file.name} />
          ) : (
            <div className="form-field-box-file-icon">
              <i className={`fa fa-${getFileIcon(getExtension(file.name))}`} />
            </div>
          )}
        </div>
      ) : (
        <div className="form-field-box-file-placeholder">
          <div className="form-field-box-file-icon">
            <i className="fa fa-upload" />
          </div>
        </div>
      )}
      <div className="form-field-box-file-input">
        <input
          type="file"
          accept={fileType}
          multiple={multiple}
          onChange={e => onFileChange({ value: e.target.files, ...defaultData })}
        />
      </div>
      {hasFile && (
        <div className="form-field-box-file-remove">
          <button onClick={e => e.preventDefault() & onFileRemove({ name, fileKey: 0 })}>
            <span>{t('common.remove')}</span>
          </button>
        </div>
      )}
    </div>
  )
}

export default function FileField(props) {
  let {
    onFileChange,
    onFileRemove,
    name,
    value,
    t,
    multiple = false,
    fileType = '.jpg,.jpeg,.png,.gif,.mp4,.mpeg,.doc,.docx,.pdf,.xls,xlsx,.csv,.txt,.zip',
    maxSize = 10485760,
    simple = false,
    inputLabel = false,
    inputIcon = false,
    info,
    box = false,
  } = props

  if (box) return <BoxFile {...props} />

  info = info || fileType.split(',').join('/')

  let defaultData = { name, fileType, multiple, maxSize }

  /* If value is string */
  if (typeof value === 'string' && value.length) value = List().push(value)

  /* If value is not set, then show upload button */
  if (!List.isList(value) || !value.size) {
    return (
      <div className="form-field-file">
        <div className="form-field-file-upload">
          <div className="form-field-file-upload-text">
            <i className={inputIcon ? inputIcon : 'fa fa-file'} />
            {inputLabel ? inputLabel : t('file.upload')}
            {simple ? null : (
              <span className="form-field-file-upload-info">{` (${
                fileType !== '*' ? fileType.split(',').join('/') + ', ' : ''
              }max. ${formatBytes(maxSize)})`}</span>
            )}
          </div>
          <input
            type="file"
            accept={fileType}
            multiple={multiple}
            onChange={e => onFileChange({ value: e.target.files, ...defaultData })}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="form-field-file form-field-file--selected">
      {value.map((file, fileKey) => {
        if (typeof file === 'string')
          file = {
            preview: file,
            name: file.split('/').pop(),
          }

        let { preview, name: fileName = '', size = false } = file

        let fileIcon = getFileIcon(getExtension(fileName))

        return (
          <div className="form-field-file-item" key={fileKey}>
            {fileIcon === 'image' ? (
              <div className="form-field-file-item-preview">
                <img src={preview} alt={fileName} />
              </div>
            ) : (
              <div className="form-field-file-item-preview form-field-file-item-preview--icon">
                <i className={`fa fa-${fileIcon}`} />
              </div>
            )}
            <div className="form-field-file-item-name">
              <span className="ellipsis">
                {fileName} {size && <span className="form-field-file-item-name-size">({formatBytes(size)})</span>}
              </span>
            </div>
            <div className="form-field-file-item-remove">
              <button onClick={e => e.preventDefault() & onFileRemove({ name, fileKey })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}
