import { connectApi } from 'api'
import { fromJS, List, Map } from 'immutable'
import React, { Component } from 'react'
import FormContext from './formContext'
import Loader from './loader'
import Messages from './messages'
import { uploadFiles } from './uploadFile'
import Validator from './validator'

function getExtension(name) {
  if (typeof name !== 'string') return null

  let nameParts = name.split('.')
  return nameParts[nameParts.length - 1]
}

let wait = fn => {
  let threshhold = 500
  let deferTimer = undefined

  return function () {
    let args = arguments
    if (deferTimer) clearTimeout(deferTimer)

    deferTimer = setTimeout(function () {
      fn.apply(this, args)
    }, threshhold)
  }
}

class Form extends Component {
  state = {
    formState: {
      values: Map(),
      changes: Map(),
      errors: {},
      errorsMap: Map(),
      messages: [],
      translate: false,
    },
    formLoading: false,
  }

  constructor(props) {
    super(props)

    let { values, translate = false } = props

    if (!Map.isMap(values)) values = fromJS(values)

    if (!Map.isMap(values)) return

    let { formState } = this.state
    formState.values = values
    formState.errors = {}
    formState.messages = []
    formState.translate = translate
    this.state.formState = formState
  }

  handleFormInit = values => {
    if (!Map.isMap(values)) values = fromJS(values)

    let { formState } = this.state
    formState.values = values
    formState.errors = {}
    formState.messages = []
    this.setState({ formState })
  }

  autosaveWait = wait(fn => fn())
  autosave = () => this.autosaveWait(() => this.handleFormSubmit())

  handleFormUpdate = (events, callback = false) => {
    if (!Array.isArray(events)) events = [events]

    let { processors = {}, autoUpdate = {}, autosave } = this.props
    let formState = { ...this.state.formState }

    formState = events.reduce((formState, event) => {
      let { values, changes } = formState

      let target = event.target || event

      let { name, value } = target

      // First check processors
      if (processors[name] != null) value = Processor(value, processors[name])

      // Check if we have auto updates
      if (autoUpdate[name] != null) {
        for (let autoName of autoUpdate[name]) {
          let autoValue = value
          if (processors[autoName]) autoValue = Processor(autoValue, processors[autoName])

          values = values.setIn(_name.split('.'), _value)
          changes = changes.setIn(_name.split('.'), _value)
        }
      }

      values = values.setIn(name.split('.'), value)
      changes = changes.setIn(name.split('.'), value)

      formState.values = values
      formState.changes = changes

      return formState
    }, formState)

    if (autosave) this.autosave()

    this.setState(
      {
        formState,
      },
      () => {
        if (typeof callback === 'function') callback()
      }
    )
  }

  handleFormFileUpdate = data => {
    let { name, value: files, multiple = false, fileType = '*', maxSize = 10485760 } = data

    let acceptedFiletypes = fileType === '*' ? [] : fileType.split(',').map(f => f.trim().replace('.', ''))

    let { t, autoUpload = false, autosave = false, api } = this.props

    let { formState } = this.state
    let { values, errors, changes } = formState

    if (!files) {
      delete formState.errors[name]
      formState.values = formState.values.removeIn(name.split('.'))
      return this.setState({ formState })
    }

    let value = multiple ? values.getIn(name.split('.'), List()) : List()

    let fileErrors = []

    for (let i = 0; i < files.length; i++) {
      let file = files[i]
      let fileError = false
      let { size, type, name } = file
      let extension = getExtension(name)

      /* Don't add files that are already selected */
      if (value.find(f => f.name === name)) fileError = t('file.errorFileTaken')

      if (size > maxSize) {
        fileError = t('file.errorMaxSize')
      } else if (acceptedFiletypes.length && acceptedFiletypes.indexOf(extension.toLowerCase()) === -1) {
        fileError = t('file.errorWrongType')
      }

      if (fileError) {
        fileErrors.push(`${t('file.error', 'Error')}: ${name} ${fileError}`)
        continue
      }

      file.preview = URL.createObjectURL(file)

      value = value.push(file)
    }

    if (fileErrors.length) {
      errors[name] = fileErrors
    } else {
      delete errors[name]
    }

    // If autoupload is enabled, run uploadFiles function now
    if (autoUpload && value.size) {
      uploadFiles(api, value).then(value => {
        formState.values = values.setIn(name.split('.'), multiple ? value : value.first())
        formState.changes = changes.setIn(name.split('.'), multiple ? value : value.first())
        formState.errors = errors
        this.setState({ formState }, () => {
          if (autosave) this.autosave()
        })
      })
    } else {
      formState.values = values.setIn(name.split('.'), value)
      formState.errors = errors
      this.setState({ formState })
    }
  }

  handleFormFileRemove = data => {
    let { name, fileKey } = data

    let { autosave = false } = this.props
    let { formState } = this.state
    let { values } = formState

    let value = values.getIn(name.split('.'), List())

    if (!List.isList(value)) {
      formState.values = values.removeIn(name.split('.'))
    } else {
      formState.values = values.setIn(name.split('.'), value.remove(fileKey))
    }
    if (autosave) this.autosave()
    this.setState({ formState })
  }

  handleFormSubmit = e => {
    if (e && e.defaultPrevented) return

    if (e && e.preventDefault) e.preventDefault()

    let { validation: propValidation = false, onSubmit } = this.props

    let stateUpdate = {}
    let { formState } = this.state
    let { values, changes } = formState
    let errors = {}
    let errorsMap = Map()
    let validation = false

    // Check the type of given validation and try to get it
    if (typeof propValidation === 'function') {
      validation = propValidation(this.props, values, changes)
    } else if (typeof propValidation === 'object') {
      validation = propValidation
    }

    // If validation is false, then form is automatically valid
    errors =
      typeof validation === 'function'
        ? validation(values, this.props.t)
        : validation
        ? Validator(values, validation, this.props.t)
        : {}

    // If there are no errors, run submit handler
    if (!Object.keys(errors).length) {
      let submitReturn = onSubmit.call(this, values, changes, this.handleFormSubmitDone)

      // If submit handler returns a promise, call done at then
      if (submitReturn && typeof submitReturn.then === 'function')
        submitReturn
          .then(res => this.handleFormSubmitDone(res))
          .catch(err => this.handleFormSubmitDone(err.response || err.data || err))

      stateUpdate.formLoading = true
    } else {
      for (let name in errors) {
        errorsMap = errorsMap.setIn(name.split('.'), errors[name])
      }
    }

    formState.errors = errors
    formState.errorsMap = errorsMap
    stateUpdate.formState = formState
    this.setState(stateUpdate)
  }

  handleFormSubmitDone = resState => {
    let prevChanges = this.state.formState.changes
    let prevValues = this.state.formState.values

    if (typeof resState !== 'object') resState = {}

    let state = {}
    state.formLoading = false

    // Update errors map
    if (resState.errors) {
      resState.errorsMap = Map()
      for (let name in resState.errors) {
        let value = resState.errors[name]
        resState.errorsMap = resState.errorsMap.setIn(name.split('.'), value)
      }
    }

    // Set messages
    if (resState.message) {
      resState.messages = resState.messages || []
      resState.messages.push({
        type: 'error',
        text: resState.message,
      })
    }

    // Reset changes
    resState.changes = Map()

    if (Array.isArray(resState.messages))
      state.messages = resState.messages.map(message =>
        typeof message === 'string' ? { type: 'error', text: message } : message
      )

    state.formState = Object.assign({}, this.state.formState, resState)
    this.setState(state, () => {
      if (typeof this.submitListener === 'function') this.submitListener(prevChanges, prevValues)
    })
  }

  handleSetLoading = loading => {
    this.setState({
      formLoading: typeof loading === 'boolean' ? loading : this.state.formLoading === false,
    })
  }

  render() {
    let {
      id,
      children,
      t,
      className = ['form'],
      stickySubmit = false,
      stickyMessages = false,
      fieldsetPadding = false,
      disableLoader = false,
      disableMessages = false,
      simple = false,
      box,
    } = this.props

    let { formState, formLoading } = this.state

    if (formLoading) className.push('form--processing')

    if (stickySubmit) className.push('form--sticky-submit')

    if (stickyMessages) className.push('form--sticky-messages')

    if (fieldsetPadding) className.push('form--fieldset-padding')

    if (simple) className.push('form--simple')

    if (box) className.push('form--box')

    let contextValue = {
      typedValues: formState.values.toJS(),
      formState,
      formLoading,
      formUpdate: this.handleFormUpdate,
      formSubmit: this.handleFormSubmit,
      formFileUpdate: this.handleFormFileUpdate,
      formFileRemove: this.handleFormFileRemove,
      formInit: this.handleFormInit,
      formSetLoading: this.handleSetLoading,
      formSetSubmitListener: listener => (this.submitListener = listener),
      t,
    }

    return (
      <FormContext.Provider value={contextValue}>
        <form id={id} onSubmit={this.handleFormSubmit} className={className.join(' ')}>
          {disableMessages || <Messages messages={formState.messages || []} />}
          {children}
          {!disableLoader && formLoading ? <Loader /> : null}
        </form>
      </FormContext.Provider>
    )
  }
}

export default connectApi(Form)
