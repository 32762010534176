import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { Map, List, fromJS } from 'immutable'
import Radios from './radios'

function EmbedPreview({ theme }) {
  let elRef = useRef()
  let embedRef = useRef()

  useLayoutEffect(() => {
    if (!embedRef.current) embedRef.current = TrustmaryEmbed.initPreview(elRef.current, theme.toJS())
  })

  return <div className="templates-embed-preview" ref={elRef} />
}

export default function EmbedTheme(props) {
  let {
    value,
    name,
    onChange,
    loading = false,
    templates,
    templateSourceField = 'templateSource',
    values,
    lang,
    templateSourceOptions,
    t,
  } = props

  let source = values.get(templateSourceField)

  let [templateOptions, setTemplateOptions] = useState(List())

  let prevConfig = useRef()

  useEffect(() => {
    let sourceLoading = source + loading.toString()
    if (prevConfig.current !== sourceLoading) {
      let items = templates.get(source, List())
      setTemplateOptions(items)
      if (items.size) onChange({ name, value: items.getIn([0, 'id']) })
    }

    prevConfig.current = sourceLoading
  })

  return (
    <div className="templates templates--embed">
      <div className="templates-source">
        <Radios name={templateSourceField} value={source} onChange={onChange} options={templateSourceOptions} />
      </div>
      <div className="templates-list">
        {!List.isList(templateOptions) || !templateOptions.size ? (
          <div className="templates-empty">{t('embedCreateWizard.template.emptyList')}</div>
        ) : (
          templateOptions.map(template => {
            let id = template.get('id')
            let isSelected = id === value
            let title = template.getIn(['info', lang, 'title'], template.getIn(['info', 'en', 'title']))

            let classes = ['templates-embed']
            if (isSelected) classes.push('templates-embed--selected')

            return (
              <div className="templates-list-item" key={id}>
                <button className={classes.join(' ')} onClick={e => e.preventDefault() & onChange({ name, value: id })}>
                  <EmbedPreview theme={template.get('theme', Map())} />
                  <div className="templates-embed-title-wrapper">
                    <div className="templates-embed-title">
                      <div className="templates-embed-title-check">
                        <span className="form-checkbox-icon-wrapper">
                          <span className={`form-checkbox-icon ${isSelected ? 'form-checkbox-icon--checked' : ''}`}>
                            {isSelected ? <i className="fa fa-check" /> : null}
                          </span>
                        </span>
                      </div>
                      <div className="templates-embed-title-name">
                        <span className="ellipsis">{title || t('embedCreateWizard.emptyName')}</span>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}
