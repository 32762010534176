import React, { Component } from 'react'
import FormStore from './formStore'
import Field from './lazyField'
import Submit from './submit'

class FormWizard extends Component {
  constructor(props) {
    super(props)

    let { steps } = props

    let [firstStep = {}] = steps

    this.state = {
      activeStep: firstStep.id || false,
    }
  }

  getOpenSteps = () => {
    let { formState, steps } = this.props
    let { values } = formState
    return steps.filter(step => (typeof step.isOpen === 'function' ? step.isOpen.call(null, values) : true))
  }

  render() {
    let { title, lang } = this.props
    let openSteps = this.getOpenSteps()

    return (
      <div className="form-wizard">
        {title ? <div className="form-wizard-title">{title}</div> : null}
        <div className="form-wizard-steps">
          {openSteps.map(step => {
            let { id, fields = [], full = false, container = false, submit = false } = step
            let classes = ['form-wizard-step']
            if (container) classes.push('form-wizard-step--container')

            if (full) classes.push('form-wizard-step--full')

            if (submit)
              return (
                <div className="form-wizard-submit" key={id}>
                  <div className="form-wizard-submit-inner">
                    <Submit text={submit} icon="plus" />
                  </div>
                </div>
              )

            return (
              <div className={classes.join(' ')} key={id}>
                <div className="form-wizard-step-fields">
                  {fields.map(field => (
                    <Field {...field} lang={lang} key={field.name} />
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default props => <FormStore>{formState => <FormWizard {...props} {...formState} />}</FormStore>
