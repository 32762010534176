import React, { useMemo } from 'react'
import { List, fromJS } from 'immutable'
import Select from 'react-select'

const flattenOptions = (options, flatten = []) =>
  options.reduce((flatten, option) => {
    if (Array.isArray(option.options)) return flattenOptions(option.options, flatten)
    return [...flatten, option]
  }, flatten)

export default function MultiSelect({ name, value: rawValue, options, onChange, simple, filterStyle }) {
  if (!List.isList(rawValue)) rawValue = List()

  const value = useMemo(() => {
    return flattenOptions(options).filter(o => rawValue.includes(o.value))
  }, [rawValue, options])

  return (
    <Select
      value={value}
      isMulti
      isClearable={false}
      name={name}
      options={options}
      className="form-react-multiselect"
      onChange={value => onChange({ name, value: fromJS(value.map(o => o.value)) })}
      styles={{
        container: styles => ({ ...styles, border: 'none' }),
        indicatorSeparator: styles => ({ display: 'none' }),
        menu: styles => ({ ...styles, zIndex: 30 }),
        control: (styles, { isFocused }) => {
          const data = {
            ...styles,
            background: '#fafafb',
            borderColor: '#a3b2c0',
            ['&:hover']: {
              boxShadow: 'none',
            },
          }

          if (isFocused) {
            data.borderColor = '#1d3a6b'
            data.offset = 'none'
            data.boxShadow = 'none'
          }

          if (simple) {
            data.background = '#fff'
            data.border = 'none'
            data.borderRadius = '0'
            data.paddingTop = '30px'
            data.paddingBottom = '6px'
          }

          if (filterStyle) {
            data.background = '#edeef1'
            data.borderColor = '#edeef1'
            data.paddingTop = '0'
            data.paddingBottom = '0'
            data.minHeight = 30
            data['&:hover'] = {
              boxShadow: 'inset 0 0 0 1px #d0dbe6',
            }
          }

          return data
        },
        option: (styles, state) => {
          styles['&:hover'] = {
            background: '#f4f5f7',
          }

          if (state.isFocused) styles.background = '#f4f5f7'

          return styles
        },
        multiValue: styles => {
          if (filterStyle) {
            styles.backgroundColor = '#fff'
            styles.boxShadow = '0 0 1px #d0dbe6'
          }

          return styles
        },
        valueContainer: styles => {
          styles.paddingTop = '4px'
          styles.paddingBottom = '4px'

          return styles
        },
      }}
    />
  )
}
