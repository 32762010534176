import React, { lazy, Suspense } from 'react'
const Field = lazy(() => import('./field'))

export default function FieldProxy(props) {
  return (
    <Suspense fallback={<div className="form-field-lazy-loader" />}>
      <Field {...props} />
    </Suspense>
  )
}
