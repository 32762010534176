import React, { useState, useRef } from 'react'
import { ChromePicker } from 'react-color'
import tinycolor from 'tinycolor2'

import './colorPicker.sass'

export default function ColorPicker(props) {
  let [open, setOpen] = useState(false)
  let inputRef = useRef(null)
  let { t, defaultColor, value = '', name, onChange, inline = false } = props

  let color = value || defaultColor

  const onInputChange = input => {
    let { value } = input
    if (value === color) return

    // Validate and turn to hex
    let c = tinycolor(value)
    if (!c.isValid()) return (input.value = color)

    changeColor(c.toHexString())
  }

  const changeColor = value => {
    onChange({ name, value })
    if (inputRef.current) inputRef.current.value = value || defaultColor
  }

  return (
    <div className="form-field form-field--color">
      <div className={`form-field-color form-field-color--${inline ? 'inline' : 'default'}`}>
        <div className="form-field-color-input">
          <button
            style={{ backgroundColor: value ? value : defaultColor }}
            onClick={e => e.preventDefault() & setOpen(!open)}
            className="form-field-color-button">
            <i className="fa fa-eye-dropper" />
          </button>
          {inline && (
            <input
              type="text"
              defaultValue={color}
              ref={inputRef}
              onBlur={e => onInputChange(e.target)}
              onKeyDown={e => {
                // Prevent enter default and save color
                if (e.key !== 'Enter') return
                e.preventDefault()
                onInputChange(e.target)
              }}
            />
          )}
        </div>
        {open ? (
          <div className="form-field-color-wrapper">
            <div className="form-field-color-overlay" onClick={e => setOpen(false)} />
            <div className="form-field-color-picker">
              <ChromePicker color={color} onChange={e => changeColor(e.hex)} disableAlpha={true} />
            </div>
          </div>
        ) : null}
        <div className="form-field-color-action">
          {defaultColor ? (
            <button
              className="button-plain form-field-color-reset"
              onClick={e => e.preventDefault() & changeColor(undefined)}>
              {t('colorPicker.resetColor')}
            </button>
          ) : (
            <button className="button-plain form-field-color-reset" onClick={e => e.preventDefault() & changeColor('')}>
              {t('colorPicker.removeColor')}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
