import axios, { AxiosResponse, AxiosStatic } from 'axios'
import React, { createContext, useContext, useMemo } from 'react'
import { TranslationContextType, useI18n } from '../i18n'
import { request, setApiToken } from './request'

export { request }

const processQuery = (path: string, query: { [field: string]: string | number }, defaultQuery = {}) => {
  query = Object.assign({}, defaultQuery, query)
  if (Object.keys(query).length) {
    const queryParts = []

    for (const key in query) {
      const value = query[key]
      if (Array.isArray(value)) {
        value.forEach(val => queryParts.push(key + '[]=' + encodeURIComponent(val)))
      } else {
        queryParts.push(key + '=' + encodeURIComponent(value))
      }
    }

    path = `${path}?${queryParts.join('&')}`
  }
  return path
}

export interface ApiContextType extends TranslationContextType {
  axios: AxiosStatic
  appApi: AppApi
  setApiToken: (state: string | boolean) => void
}

export const ApiContext = createContext<ApiContextType>({} as never)
export const ApiStore = ApiContext.Consumer

interface WindowGlobals extends Window {
  APP_API_ROOT: string
}

type Query = { [name: string]: string | number }
interface LoginResponse {
  organization_id: string
  token: string
}

export interface AppApi {
  get<R>(path: string, query?: Query): Promise<AxiosResponse<R>>
  post<R, P = unknown>(path: string, data?: P, query?: Query): Promise<AxiosResponse<R>>
  patch<R = void, P = unknown>(path: string, data: Partial<P>, query?: Query): Promise<AxiosResponse<R>>
  put<R = void, P = Record<string, unknown>>(path: string, data: P, query?: Query): Promise<AxiosResponse<R>>
  delete<R = void, P = Record<string, unknown>>(path: string, data?: P, query?: Query): Promise<AxiosResponse<R>>
  sendMagickLink(email: string, pwa?: boolean): Promise<{ success: boolean }>
  loginWithMagicLink(email: string, secret: string): Promise<LoginResponse>
  uploadFilePath: string
}

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  const i18nState = useI18n()
  const { lang } = i18nState
  const defaultQuery = { lang }
  // TODO: Fix globals
  const APP_API_ROOT = (window as unknown as WindowGlobals).APP_API_ROOT
  const appApi: AppApi = useMemo(() => {
    return {
      get(path: string, query = {}) {
        return request('GET', APP_API_ROOT + processQuery(path, query, defaultQuery), {}, false)
      },
      post<T>(path: string, data: T, query = {}) {
        return request('POST', APP_API_ROOT + processQuery(path, query, defaultQuery), data, false)
      },
      patch<T>(path: string, data: Partial<T>, query = {}) {
        return request('PATCH', APP_API_ROOT + processQuery(path, query, defaultQuery), data, false)
      },
      put<T>(path: string, data: T, query = {}) {
        return request('PUT', APP_API_ROOT + processQuery(path, query, defaultQuery), data, false)
      },
      delete<T>(path: string, data: T, query = {}) {
        return request('DELETE', APP_API_ROOT + processQuery(path, query, defaultQuery), data, false)
      },
      sendMagickLink(email: string, pwa = false) {
        return axios.post<{ email: string; pwa: boolean }, { success: boolean }>(
          processQuery(`${APP_API_ROOT}/auth/magiclink`, {}, defaultQuery),
          { email, pwa }
        )
      },
      loginWithMagicLink(email: string, secret: string) {
        return axios.post<{ email: string; pwa: boolean }, LoginResponse>(
          processQuery(`${APP_API_ROOT}/auth/magiclink`, {}, defaultQuery),
          { email, secret }
        )
      },
      uploadFilePath: '/file',
    }
  }, [lang])

  const contextValue: ApiContextType = {
    axios,
    appApi,
    setApiToken,
    ...i18nState,
  }

  return <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
}

export function connectApi<P>(component: React.ComponentType<P>): (props: P) => React.ReactElement<P> {
  return props => <ApiStore>{apiState => React.createElement(component, { ...props, ...apiState })}</ApiStore>
}

export function useApi() {
  return useContext(ApiContext)
}
