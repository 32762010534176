import { fromJS, List, Map } from 'immutable'
import React, { Component } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { generate as genId } from 'shortid'

const Handle = SortableHandle(props => (
  <div className="options-handle">
    <i className="fa fa-bars" />
  </div>
))

const OptionItem = SortableElement(({ option, onChange, onKeyDown, lang, fieldIndex: index, id, mainLang, t }) => {
  let label = option.get('label', Map())
  let value = option.get('value')
  return (
    <div className="options-item">
      <div className="options-item-handle">
        <Handle />
      </div>
      <div className="options-item-input">
        <input
          type="text"
          name={`${index}.label.${lang}`}
          id={`${id}-${value}`}
          value={label.get(lang, '')}
          placeholder={label.get(mainLang || 'en', '')}
          onChange={onChange}
          onKeyDown={e => onKeyDown(e, index)}
        />
      </div>
    </div>
  )
})

const OptionList = SortableContainer(({ options, onChange, onKeyDown, id, lang, mainLang, t }) => {
  return (
    <div className="options-list">
      {options.map((option, index) => (
        <OptionItem
          key={option.get('value')}
          fieldIndex={index}
          index={index}
          option={option}
          id={id}
          lang={lang}
          mainLang={mainLang}
          onChange={onChange}
          onKeyDown={onKeyDown}
          t={t}
        />
      ))}
    </div>
  )
})

export default class OptionsField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.name.replace(/\./g, ''),
    }
  }

  handleOptionChange = e => {
    let { name: inputName, value: inputValue } = e.target || e
    let { value, name, onChange } = this.props

    if (!List.isList(value)) value = List()

    onChange({
      name,
      value: value.setIn(inputName.split('.'), inputValue),
    })
  }

  handleCreateChange = (e, index = -1) => {
    let { value: inputValue = '' } = e.target || e
    let { id } = this.state

    if (e.target) e.target.value = ''

    let { value, name, lang = 'en', onChange } = this.props

    let optionId = genId()

    if (!List.isList(value)) value = List()

    let label = {}
    label[lang] = inputValue
    let option = fromJS({
      value: optionId,
      label,
    })

    if (index < 0) {
      value = value.push(option)
    } else {
      value = value.insert(index, option)
    }

    onChange(
      {
        name,
        value,
      },
      () => {
        /* Focus newly created option input, we need to do this after rerender, that's why it's callback to onChange */
        let input = this._parent.querySelector(`#${id}-${optionId}`)
        if (input) input.focus()
      }
    )
  }

  removeOption = index => {
    let { value, name, onChange } = this.props
    let { id } = this.state
    onChange(
      {
        name,
        value: value.remove(index),
      },
      () => {
        if (index === 0) return

        let optionId = value.getIn([index - 1, 'value'])
        let input = this._parent.querySelector(`#${id}-${optionId}`)
        if (input) input.focus()
      }
    )
  }

  handleOptionKeyDown = (e, index) => {
    if (e.key === 'Backspace' && e.target.value === '') {
      e.preventDefault()
      this.removeOption(index)
    }

    if (e.key === 'Enter') {
      e.preventDefault()
      this.handleCreateChange({}, index + 1)
    }
  }

  handleReorder = ({ oldIndex, newIndex }) => {
    let { value, name, lang = 'en', onChange } = this.props
    let source = value.get(oldIndex)

    value = value
      .setIn([oldIndex, '_delete'], true) // Flag source to be deleted
      .insert(oldIndex > newIndex ? newIndex : newIndex + 1, source)
      .filter(s => s.get('_delete', false) === false) // Remove item with _delete flag

    onChange({ name, value })
  }

  render() {
    let { value, onChange, name, lang = 'en', mainLang = 'en', t } = this.props
    let { id } = this.state

    if (!List.isList(value)) value = List()

    let createId = `options-create-${id}`

    return (
      <div className="form-field form-field--options" ref={el => (this._parent = el)}>
        <div className="options">
          <OptionList
            options={value}
            onChange={this.handleOptionChange}
            onKeyDown={this.handleOptionKeyDown}
            onSortEnd={this.handleReorder}
            name={name}
            lang={lang}
            mainLang={mainLang}
            id={id}
            useDragHandle
            t={t}
          />
          <div className="options-item options-item--create">
            <label className="options-item-handle" htmlFor={createId}>
              <div className="options-handle">
                <i className="fa fa-plus" />
              </div>
            </label>
            <div className="options-item-input">
              <input
                type="text"
                id={createId}
                placeholder={t('common.createOption')}
                onChange={this.handleCreateChange}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
