import React from 'react'

export default function Radios({ name, options = [], value, onChange, disabled = false }) {
  return (
    <div className="form-field form-field--radios" role="radiogroup">
      {options.map(({ value: optionValue, label }) => {
        let classes = ['form-radio']
        let isChecked = optionValue === value

        if (isChecked) classes.push('form-radio--checked')

        return (
          <button
            className={classes.join(' ')}
            onClick={e => e.preventDefault() & onChange({ name, value: optionValue })}
            disabled={disabled}
            key={optionValue}>
            <span className="form-radio-icon-wrapper">
              <span className="form-radio-icon">{isChecked ? <i className="fa fa-check" /> : null}</span>
            </span>
            <span className="form-radio-label">{label}</span>
          </button>
        )
      })}
    </div>
  )
}
