import {
  AcceptTermBody,
  AcceptTermResult,
  AuthBody,
  AuthResult,
  Feature,
  Organization,
  UserData,
} from '@tm/types/common/app-api/auth'
import { EventPollStatus } from '@tm/types/common/app-api/event-poll'
import { History, Location } from 'history'
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ApiContextType, useApi } from '../api'
import { request } from '../api/request'
import { WindowGlobals } from '../window'
import AuthLoader from './loader'
import TermOverlay from './termOverlay'

function checkIfPwa() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return (window.navigator as any).standalone == true || window.matchMedia('(display-mode: standalone)').matches
}

export const updateMixpanel = (userData: UserData) => {
  // TODO: Do this typing cleanly
  const mixpanel = (window as unknown as WindowGlobals).mixpanel
  if (!mixpanel) return

  const { contact_id, email: contact_email, name: contact_name, organization, ip, marketingSource, phone } = userData

  if (!organization) return

  const { appSubscriptionInfo = { created_at: undefined, renew_at: undefined, plan_id: undefined } } = organization
  const {
    created_at: subscription_created_at,
    renew_at: subscription_renew_at,
    plan_id: subscription_plan_id,
  } = appSubscriptionInfo

  const marketingProps: { [key: string]: string } = {}

  // Include marketingSource to mixpanel profile.
  // Object has landingpage, utm tags & referer info from where user came to our website before creating account.
  if (marketingSource && Object.keys(marketingSource).length > 0) {
    for (const key in marketingSource) {
      marketingProps[`marketing_${key}`] = marketingSource[key]
    }
  }

  const contactProps = {
    distinct_id: contact_id,
    organization_id: organization.id,
    organization_name: organization.name,
    operating_company_id: organization.operating_company_id,
    $email: contact_email,
    $name: contact_name,
    subscription_created_at,
    subscription_renew_at,
    subscription_plan_id,
    ip,
    phone,
    ...marketingProps,
  }

  mixpanel.identify(contact_id)

  const definedContactProps = Object.keys(contactProps).reduce((data, key) => {
    if (typeof contactProps[key] === 'string') {
      data[key] = contactProps[key]
    }
    return data
  }, {})

  if (Object.keys(definedContactProps).length) mixpanel.people.set(definedContactProps)

  const orgProps = {
    distinct_id: organization.id,
    $name: organization.name,
    operating_company_id: organization.operating_company_id,
    subscription_created_at,
    subscription_renew_at,
    subscription_plan_id,
    ...marketingProps,
  }

  const definedOrgProps = Object.keys(orgProps).reduce((data, key) => {
    if (typeof orgProps[key] === 'string') {
      data[key] = orgProps[key]
    }
    return data
  }, {})

  if (Object.keys(definedOrgProps).length) mixpanel.get_group('organization_id', organization.id).set(definedOrgProps)

  mixpanel.track('PAGE_LOAD')
}

const resetMixpanel = () => {
  const mixpanel = (window as unknown as WindowGlobals).mixpanel
  if (!mixpanel) return
  mixpanel.reset()
}

const updateIntercom = (userData: UserData) => {
  // TODO: Do this typing cleanly
  const Intercom = (window as unknown as WindowGlobals).Intercom
  if (!Intercom) return

  try {
    const { email, name, contact_id } = userData

    const {
      name: company,
      id: organization_id,
      operating_company_id,
      appSubscriptionInfo: { created_at, renew_at, plan_id } = {
        created_at: undefined,
        renew_at: undefined,
        plan_id: undefined,
      },
    } = userData.organization || {}

    Intercom('boot', {
      app_id: 'skhk0scq',
      email,
      user_id: contact_id,
      user_name: name,
      company: {
        id: organization_id,
        name: company,
        plan: plan_id,
        created_at,
        renew_at,
        operating_company_id,
      },
    })
  } catch (err) {
    console.log(err)
  }
}

const resetIntercom = () => {
  const Intercom = (window as unknown as WindowGlobals).Intercom
  if (!Intercom) return
  Intercom('shutdown')
}

const updateStonly = (userData: UserData) => {
  // TODO: Do this typing cleanly
  const stonlyTrack = (window as unknown as WindowGlobals).stonlyTrack
  if (!stonlyTrack) return

  try {
    const { email, name: contact_name, contact_id } = userData

    const {
      name: organization_name,
      id: organization_id,
      operating_company_id,
      appSubscriptionInfo: { created_at: plan_created_at, renew_at: plan_renew_at, plan_id } = {
        created_at: undefined,
        renew_at: undefined,
        plan_id: undefined,
      },
    } = userData.organization || {}

    stonlyTrack('identify', contact_id, {
      email,
      contact_name,
      organization_id,
      organization_name,
      plan_id,
      plan_created_at,
      plan_renew_at,
      operating_company_id,
    })
  } catch (err) {
    console.log('Stonly failed')
    console.log(err)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isObject = (obj: any): obj is Record<string, unknown> => typeof obj === 'object'

const getIn = (obj: Record<string, unknown>, keys: string[]): Record<string, unknown> | unknown => {
  if (!Array.isArray(keys)) return

  const [key, ...rest] = keys
  const value = obj[key]

  if (typeof value === 'undefined') return

  if (rest.length) {
    if (!isObject(value)) return

    return getIn(value, rest)
  }

  return value
}

// TODO: Rewrite to use a suitable interface
// eslint-disable-next-line @typescript-eslint/ban-types
function createMap<R>(obj: {} | undefined | null): R {
  if (typeof obj !== 'object' || obj == null) obj = {}

  if (isObject(obj)) {
    // This will always be the case
    obj.get = function (key: string, defaultValue: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return (this as Record<string, unknown>)[key] || defaultValue
    }

    obj.getIn = function (keys: string[], defaultValue: unknown) {
      return getIn(this, keys) || defaultValue
    }
  }

  return obj as R
}

interface AuthContextType extends ApiContextType {
  history: History
  location: Location
  loggedIn: boolean | undefined
  logout: () => void
  // TODO: undefined userData needs to be handled by the consumers - this is just to avoid crashes
  // eslint-disable-next-line @typescript-eslint/ban-types
  userData: UserData
  authLoading: boolean
  organization: Organization
  pwa: boolean
  updateToken: (token: string) => void
  changeOrganization: (org: string) => void
  hasFeature: (feature: Feature | Feature[]) => boolean
  getLimit: (limit: string) => number
  testLimit: (limit: string, count: string | number) => boolean
  toggleFeature: (feature: Feature) => void
  updateEventPollStatus: (eventPollStatus: EventPollStatus) => void
}

/* Store for auth */
export const AuthContext = createContext<AuthContextType>({} as never)
export const AuthStore = AuthContext.Consumer

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const apiState = useApi()
  const { appApi, setApiToken, t } = apiState
  const history = useHistory()
  const location = useLocation()

  const loaded = useRef(false)
  const [userData, setUserData] = useState<UserData>({
    orgFeatures: [],
  } as never)
  const [authLoading, setAuthLoading] = useState(false)
  const [authError, setAuthError] = useState(false)
  const [loggedIn, setLoggedIn] = useState<boolean>()

  /* Accept term management */
  const [acceptTermAction, setAcceptTermAction] = useState(false)
  const [acceptTermLoading, setAcceptTermLoading] = useState(false)

  const logout = async () => {
    await request('POST', '/api/auth/signout')
    setApiToken(false)
    setLoggedIn(false)
    setUserData({} as never)
    resetIntercom()
    resetMixpanel()
    history.push('/login')
  }

  const loadAuthData = useCallback((set_organization: string | false = false) => {
    return request<AuthResult, AuthBody>('POST', '/api/auth', { set_organization })
      .then(res => res.data)
      .then(res => {
        setAuthLoading(false)

        const { isLoggedIn, token, userData, organization, acceptTermAction } = res

        userData.organization = createMap<Organization>(organization)

        setUserData(userData)
        setApiToken(token)
        setAcceptTermAction(acceptTermAction)
        setLoggedIn(isLoggedIn)

        if (userData && userData.email) {
          updateMixpanel(userData)
          updateIntercom(userData)
          updateStonly(userData)
        }

        setAuthLoading(false)

        if (loaded.current) {
          let redirect: string | false = false
          const { pathname } = location
          if (pathname.startsWith('/embed/')) redirect = '/embed'
          if (pathname.startsWith('/survey/')) redirect = '/survey'
          if (pathname.startsWith('/contact/list/')) redirect = '/contact'

          if (redirect) history.push(redirect)
        }

        // Save initial load
        loaded.current = true
      })
      .catch(err => {
        console.log(err)
        if (location.pathname === '/logout') {
          void logout()
        } else {
          setAuthError(true)
        }
      })
  }, [])

  useEffect(() => {
    setAuthLoading(true)
    void loadAuthData()
  }, [])

  const updateToken = () => {
    setAuthLoading(true)
    return loadAuthData()
  }

  const changeOrganization = (organization_id: string) => {
    setAuthLoading(true)
    return loadAuthData(organization_id)
  }

  const acceptTerm = (term_id: string) => {
    if (acceptTermLoading) return

    setAcceptTermLoading(true)
    return appApi.post<AcceptTermResult, AcceptTermBody>('/auth/accept-term', { term_id }).then(({ data }) => {
      setAcceptTermLoading(false)
      setAcceptTermAction(data.success ? false : acceptTermAction)
    })
  }

  const hasFeature = (feature: Feature | Feature[]): boolean => {
    if (!userData) return false
    const { orgFeatures } = userData

    if (typeof feature === 'object') return feature.some(feature => hasFeature(feature))

    return orgFeatures.includes(feature)
  }

  const getLimit = (key: string) => {
    if (!userData) return 0
    const { appSubscriptionInfo = { limits: {} } } = userData.organization
    const { limits = {} } = appSubscriptionInfo
    const value = parseInt(appSubscriptionInfo[key] || limits[key] || 0)
    return isNaN(value) ? 0 : value
  }

  const testLimit = (key: string, count: string | number) => {
    const testValue = getLimit(key)
    if (testValue === -1) return true
    const numberCount = typeof count === 'number' ? count : parseInt(count)
    return numberCount < testValue
  }

  const toggleFeature = (feature: Feature) => {
    if (!userData) return
    let { orgFeatures } = userData
    orgFeatures = orgFeatures.includes(feature) ? orgFeatures.filter(f => f !== feature) : [...orgFeatures, feature]
    setUserData({ ...userData, orgFeatures })
  }

  const updateEventPollStatus = useCallback(
    (eventPollStatus: EventPollStatus) => {
      setUserData({ ...userData, eventPollStatus })
    },
    [userData, setUserData]
  )

  if (authLoading && location.pathname !== '/start') return <AuthLoader error={authError} t={t} />

  const contextValue: AuthContextType = {
    history,
    location,
    loggedIn,
    logout,
    userData,
    authLoading,
    organization: userData?.organization || createMap({}),
    pwa: checkIfPwa(),
    updateToken,
    changeOrganization,
    hasFeature,
    getLimit,
    testLimit,
    toggleFeature,
    updateEventPollStatus,
    ...apiState,
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {authError && (
        <div className="auth-error">
          <div className="message message--error">{t('error.auth')}</div>
        </div>
      )}
      {children}
      {acceptTermAction && (
        <TermOverlay
          acceptTermAction={acceptTermAction}
          acceptTerm={acceptTerm}
          acceptTermLoading={acceptTermLoading}
          t={t}
        />
      )}
    </AuthContext.Provider>
  )
}

export function connectAuth<P>(component: React.ComponentType<P>) {
  return (props: P) => <AuthStore>{state => React.createElement(component, { ...props, ...state })}</AuthStore>
}

export function useAuth() {
  return useContext(AuthContext)
}
